import { graphql } from 'gatsby';
import React from 'react';
import Banner from '../../../components/blog/Banner';
import BlogOverview from '../../../components/blog/BlogOverview';
import LatestPost from '../../../components/blog/two/LatestPost';
import Breadcrumb from '../../../components/common/Breadcrumb';
import PropTypes from 'prop-types';
import { Layout } from '../../components/common';
import { MetaData } from '../../components/common/meta';

const BlogPosts = ({ data, location }) => {
  const posts = data.allGhostPost.edges;

  return (
    <>
      <MetaData data={data} location={location} type='series' />
      <Layout>
        <Banner title='Blog'>
          <Breadcrumb
            breadcrumbs={[
              ['Home', '/'],
              ['Blog', '/blog'],
            ]}
          />
        </Banner>
        <BlogOverview posts={posts} />
        <LatestPost articles={posts} posts={posts.slice(5, 1000000)} />
      </Layout>
    </>
  );
};

BlogPosts.PropTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.object.isRequired,
  }),
};

export default BlogPosts;

export const pageBlogQuery = graphql`
  query GhostPostQuery {
    allGhostPost {
      edges {
        node {
          url
          title
          feature_image
          id
          slug
          excerpt
        }
      }
    }
  }
`;
