import Image from 'next/image';
import { BsArrowRight } from 'react-icons/bs';
import { FaSearch } from 'react-icons/fa';
import articleData from './articleData';
import React from 'react';
import { Link } from 'gatsby';
import { Box } from '@mui/material';

import placeholderImage from '/public/images/blog/single-poster.png';

const BlogOverview = ({ posts }) => {
  const blogPost = posts[0].node;
  return (
    <section className='blog__overview section__space'>
      <div className='container'>
        <div className='blog__overview__area'>
          <div className='search__bar'>
            <h2>Articles & Resources</h2>
            {/* <form action='#' method='post'>
              <div>
                <input
                  type='text'
                  name='post_search'
                  id='postSearch'
                  placeholder='Search for articles'
                  required='required'
                />
                <i>
                  <FaSearch />
                </i>
              </div>
              <button type='submit' className='button'>
                Search
              </button>
            </form> */}
          </div>
          <div className='row'>
            <div className='col-lg-7'>
              <div className='featured__large__post'>
                <Link to={'/' + blogPost.slug} className='thumbnail'>
                  <img
                    src={blogPost.feature_image || placeholderImage}
                    alt={blogPost.title}
                  />
                </Link>
                <div className='blog__content'>
                  <h4>
                    <Link to={'/' + blogPost.slug}>{blogPost.title}</Link>
                  </h4>
                  <p>{blogPost?.excerpt && blogPost?.excerpt?.slice(0, 140)}</p>
                  <Link to={'/' + blogPost.slug}>
                    Read More <BsArrowRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-5'>
              <div className='featured__post'>
                {posts.slice(1, 5).map((item) => {
                  const {
                    id,
                    img = item.node.feature_image,
                    title,
                    slug,
                    excerpt,
                  } = item.node;
                  return (
                    <div key={id} className='featured__post-single'>
                      <Link to={'/' + slug} className='thumbnail'>
                        <img src={img || placeholderImage} alt={title} />
                      </Link>
                      <div className='featured__post-single-content'>
                        <h5>
                          <Link to={'/' + slug}>{title}</Link>
                        </h5>
                        <p>{excerpt && excerpt?.slice(0, 20)}</p>
                        <Link to={'/' + slug}>
                          Read More <BsArrowRight />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* <div className='col-xl-8 '>
              <Box sx={{ mt: 3.85 }}>
                <div className='left__wrap'>
                  <div className='featured__post'>
                    <div className='row gap-1'>
                      {posts.slice(4, 1000000).map((item) => {
                        const {
                          id,
                          img = item.node.feature_image,
                          title,
                          slug,
                        } = item.node;
                        return (
                          <div
                            key={id}
                            className='featured__post-single  col col-12'
                          >
                            <Box sx={{ width: '16rem' }}>
                              <Link to={'/' + slug} className='thumbnail'>
                                <img src={img} alt={title} />
                              </Link>
                            </Box>

                            <div className='featured__post-single-content'>
                              <h5>
                                <Link to={'/' + slug}>{title}</Link>
                              </h5>
                              <Link to={'/' + slug}>
                                Read More <BsArrowRight />
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Box>
            </div>

            <div className='col-xl-4'>
              <div className='right__wrap'>
                <div className='blog__popular'>
                  <h5 className='neutral-top'>Popular Articles</h5>
                  {articleData.slice(3, 6).map((item) => {
                    const { id, img, title } = item;
                    return (
                      <div key={id} className='blog__popular__single'>
                        <Link to='/blog/{id}' className='thumbnail'>
                          <img src={img} alt={title} />
                        </Link>
                        <div className='blog__popular__single-content'>
                          <h6>
                            <Link to='/blog/{id}'>{title}</Link>
                          </h6>
                          <Link to='/blog/{id}'>
                            Read More <BsArrowRight />
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogOverview;
