import { AnimatePresence } from 'framer-motion';
import Image from 'next/image';
import { useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import PostCardHorizontal from '../../cards/PostCardHorizontal';
import SubscribeCard from '../../cards/SubscribeCard';
import Pagination from '../../common/Pagination';
import Isotope from '../../isotope/Isotope';
import articleData from '../articleData';
import React from 'react';
import { Link } from 'gatsby';
import { Box } from '@mui/material';

import placeholderImage from '/public/images/blog/single-poster.png';

const Tags = ({ itemCatagory, filterCategory }) => {
  const [activeBTn, setActiveBTn] = useState(0);

  const handelClick = (item, i) => {
    setActiveBTn(i);
    filterCategory(item);
  };

  return (
    <div className='filter__bar'>
      <h3>Latest Posts</h3>
      <div className='filter__bar__tabs'>
        {itemCatagory.map((item, i) => (
          <button
            key={item}
            className={`filter__bar__tab button button--effect ${
              activeBTn == i ? ' ' : 'button--secondary'
            }`}
            onClick={() => handelClick(item, i)}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  );
};

const Content = ({ filterDatas }) => {
  return (
    <div className='row latest__blog__shuffle__list'>
      <AnimatePresence>
        {filterDatas?.map((item) => (
          <PostCardHorizontal key={item.id} data={item} />
        ))}
      </AnimatePresence>
    </div>
  );
};

const LatestPost = ({ articles, posts }) => {
  return (
    <section className='latest__post section__space'>
      <div className='container'>
        <div className='latest__post__area'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='left__wrap'>
                {/*@TODO: Latest Post */}
                {/* <Isotope url='allpost' Tags={Tags} Content={Content} /> */}
                <div className='featured__post'>
                  {posts.map((item) => {
                    const {
                      id,
                      img = item.node?.feature_image,
                      title,
                      slug,
                    } = item.node;
                    return (
                      <div key={id} className='featured__post-single'>
                        <Box sx={{ width: '16rem' }}>
                          <Link to={'/' + slug} className='thumbnail'>
                            <img src={img || placeholderImage} alt={title} />
                          </Link>
                        </Box>

                        <div className='featured__post-single-content'>
                          <h5>
                            <Link to={'/' + slug}>{title}</Link>
                          </h5>
                          <Link to={'/' + slug}>
                            Read More <BsArrowRight />
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/*@TODO: pagination here */}
                {/* <Pagination /> */}
              </div>
            </div>
            {/* <div className='col-xl-4'>
              <div className='right__wrap'>
                <div className='blog__popular'>
                  <h5 className='neutral-top'>Popular Articles</h5>
                  {articles.slice(0, 3).map((item) => {
                    const {
                      id,
                      img = item.node?.feature_image,
                      title,
                      slug,
                    } = item.node;
                    return (
                      <div key={id} className='blog__popular__single'>
                        <Link to={'/' + slug} className='thumbnail'>
                          <img src={img || placeholderImage} alt={title} />
                        </Link>
                        <div className='blog__popular__single-content'>
                          <h6>
                            <Link to={'/' + slug}>{title}</Link>
                          </h6>

                          <Link to={'/' + slug}>
                            Read More <BsArrowRight />
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestPost;
